$color-1: #008ED1;
$color-2: #7A7A7A;
$color-3: #F2F2F2;
$color-4: #F8475F;
$color-5: #000000;
$color-6: #E6E8EA;
$color-7: #BDBDBD;
$text-color-1: #111111;
$white:   #fff;
$black:   #000;
