/* Slider */
.slick-arrow {
  width: 14px;
  height: 22px;
  transition: all .2s ease-out;
  border: none;
  padding: 0;
  text-indent: 0;
  outline: none;
  text-align: center;
  position: absolute;
  z-index: 5;
  background: url('./../images/arrow.svg') no-repeat;
  font-size: 0;
  cursor: pointer;

  &.slick-prev {
      top: 50%;
      left: 5%;
      transform: scaleX(-1);

      &:before {
        display: none;
      }
  }

  &.slick-next {
      top: 50%;
      right: 5%;

      &:before {
        display: none;
      }
  }
}

.slick-dots {
  position: absolute;
  bottom: 37px;
  right: 15px;
  left: 15px;
  z-index: 99;
  list-style: none;
  text-align: center;

  li {
    display: inline-block;
    margin-right: 4px;
    margin-left: 4px;

    &:hover,
    &.slick-active {
      button {
        background-color: $color-4;
      }
    }
  }

  button {
    width: 7px;
    height: 7px;
    font-size: 0;
    background: $color-7;
    border: 0;
    outline: none;
    border-radius: 100%;
    cursor: pointer;
    appearance: none;
    padding: 0;
  }
}

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
