%common-button{
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  background: $color-3;
  border-radius: 5px;
  color: $black;
  border: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-around;
  width: 200px;
  margin: 0 auto;

  &:last-child {
    margin-top: 25px;
  }

  &:hover {
      text-decoration: none;
      background: darken($color-3, 10%);
  }

  &:active,
  &:focus {
      text-decoration: none;
      background: darken($color-3, 20%);
  }
}