%buy-button {
  font-weight: 500;
  font-style: normal;
  font-size: 15.309px;
  line-height: 18px;
  background: $color-5;
  border-radius: 5px;
  color: $white;
  border: 0;
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
  width: 125px;
  margin: 0 auto;
  cursor: pointer;

  &:hover {
    text-decoration: none;
    background: darken($color-4, 10%);
  }

  &:active,
  &:focus {
    text-decoration: none;
    background: darken($color-4, 20%);
  }
}